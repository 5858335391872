.home-banner, .home-btn-container{
    height:80vh;
    width:100vw;
    background-size:cover;
    background-position:center;
    position:relative;
}
.home-transparency{
    height:80vh;
    width:100vw;
    position:absolute;
    background-color:rgba(255, 255, 255, 0.7);
    top:0;
    left:0;
}
.home-btn-container{
    height:80vh;
    width:100vw;
    position:absolute;
    top:0;
    left:0;
    z-index:2;
    margin:0 auto;
    gap:5%;
    padding-top:100px;
}
.button-home{
    height:30vh;
    width:25%;
    font-size:30px;
    text-transform:uppercase;
    font-family:var(--medium-font);
    border-radius:40px;
    border:1px solid var(--secondary-color);
    background-color:white;
    color:var(--secondary-color) !important;
    text-decoration:none !important;
}
.button-home img{
    width:70px;
}

@media screen and (max-width:767px){
    .button-home{
        height:20vh;
        width:80%;
        margin:auto;
        margin-top:30px;
    }
    .home-btn-container{
        padding-top:0;
    }
}