@font-face {
  font-family: 'Barlow_Medium';
  font-style: normal;
  font-weight: 500;
  src:
    local("Barlow_Medium"),
    url("../fonts/Barlow-Medium.ttf") format("truetype");
}
@font-face {
  font-family: 'Barlow_ExtraLight';
  font-style: normal;
  src:
    local("Barlow_ExtraLight"),
    url("../fonts/Barlow-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: 'Barlow_Regular';
  font-style: normal;
  src:
    local("Barlow_Regular"),
    url("../fonts/Barlow-Regular.ttf") format("truetype");
}

:root{
  --primary-color: #0f5aa4;
  --secondary-color:#212529;
  --light-font: 'Barlow_ExtraLight';
  --medium-font: 'Barlow_Medium';
  --regular-font: 'Barlow_Regular';
}

.App {
  text-align: center;
}
body{
  
  font-family:var(--regular-font) !important;
}



.vinculo{
  cursor:pointer;
  display: inline-block;
}
.general-subtitle{
  font-size:24px;
  text-transform: uppercase;
}
.pointer{
  cursor:pointer;
}
.modal-dialog {
  max-width: 90vw !important;
}
.label{
  width:200px;
}
.ml-50{
  margin-left:50% !important;
}
.form-switch {
  width: 100%;
}
.form-switch .form-check-input{
  margin:auto;
}
.text-white, .text-white a {
  color:white !important;
  text-decoration: none !important;
}
/*calendar*/
.fc-scroller{
  overflow: auto !important;
}
.fc-event-main, .fc-day{
  cursor:pointer !important;
}
/*paginado*/
.pagina-actual{
  color:var(--primary-color);
  font-weight: bold;
}
.inactive{
  color:grey;
}

/*formularios*/
.invalid-feedback{
  position:absolute;
  bottom:5px;
  left:200px;
}
.invalid-feedback-datepicker,.invalid-feedback-timepicker {
  display:none;
  margin-left:20px;
  margin-top:5px;
  font-size: .875em;
  color: var(--bs-form-invalid-color);
}
.react-datepicker__input-container, .react-datepicker-wrapper{
  width:100%;
}
.form-control.react-time-picker {
  width:auto !important;
}
.react-time-picker__wrapper{
  border:none !important;
}

.btn-dark a{
  color:white !important;
  text-decoration:none !important;
}

label.form-check-label {
  margin-left:15px;
}
.modal-body .form-switch{
  margin-top:20px;
  display: flex;
  padding-left: 0;
  align-items:center;
}
.form-check-input{
  margin:0 !important;
  
}

/*nav*/
.nav-link{
  color:var(--secondary-color) !important;
  text-transform:uppercase;
}
.navbar>.container-fluid {
  justify-content: flex-end !important;
}
.header-logo{
  width:50px;
  height:auto;
   top:7px;
   left:12px;
   z-index:9;
}
.ml-80-desktop{
  margin-left:70px !important;
}
.ml-50-desktop{
  margin-left:45px !important;
}

.input-money-feedback{
  left: 25px !important;
    top: 34px;
}
.table-responsive{
  min-width:100%;
}

@media(max-width:767px){
  .ml-80-desktop, .ml-50-desktop{
    margin-left:0px !important;
  }
  .margin-mobile-5vw{
    width:90vw;
    margin-left:5vw;
  }
  .label{
    width:auto;
    margin-right:10px;
  }
  .d-flex{
    display:block !important;
  }
  .always-flex{
    display:flex !important;
  }
  .css-b62m3t-container {
    margin-top: 20px;
  }
  .w-25, .w-50{
    width:100% !important;
  }

  .modal-body .form-switch{
    width:100%;
    justify-content:center;
  }
  .invalid-feedback {
    left: 0;
}

}