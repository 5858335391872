.loader-full-container {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    z-index: 9;
  }

  .loader-container{
    position:absolute;
    width:100%;
    height:100px;
    z-index:5;
    top:40%;
}

.intro-loader{
    width:100px;
    margin:auto;
    animation: girando 3s linear infinite;
    display: block;
}

@keyframes girando{
    0% {
        transform: rotate(0deg)
    } to {
        transform: rotate(1turn)
    }
}
